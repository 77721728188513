.header {
	&__logoWrapper {
		align-self: center;
	}

	&__logoWrapper,
	&__logoWrapper svg {
		width: 6.1875em;
		height: 4em;
	}

	&__titleWrapper {
		margin-top: 2em;
	}

	&__title {
		margin: 0;

		color: #000;
		text-align: center;
		font-size: 2.75em;
		font-weight: 700;
		line-height: 100%;
	}

	&__descriptionWrapper {
		margin-top: 1em;
		max-width: 26em;
	}

	&__description {
		color: #1c1c1c;

		text-align: center;
		font-size: 0.875em;
		font-weight: 400;
		line-height: 142.857%;

		margin: 0;
	}

	&__descriptionLink {
		text-decoration-line: underline;
	}
}
