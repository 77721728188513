.orderType {
	padding: 12px 16px;

	&__list {
		display: flex;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		margin: 0;
		padding: 0;
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__button {
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		padding: 7px 8px 5px;
		border-radius: 8px;
		display: flex;
		align-items: center;

		svg {
			opacity: 0.6;
			margin-left: 2px;
		}

		&___checked {
			color: #fff;
			background: #4e55a2;

			svg {
				opacity: 1;
			}
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				background: #4e55a220;
			}

			&___checked {
				&:hover {
					background: #4e55a2;
				}
			}
		}
	}
}
