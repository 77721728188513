.range {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	padding: 21px 16px 12px;

	&:hover {
		.range__thumbText {
			opacity: 1;
		}
	}

	&___darker {
		.range__track {
			background-color: rgba(235, 235, 245, 0.6);
		}

		.range__trackButton {
			border: 2px solid rgba(235, 235, 245, 0.6);
			background-color: #2c2c2e;

			&___checked {
				background-color: #4e55a2;
				border: 2px solid #fff;
			}

			@media (hover: hover) and (pointer: fine) {
				transition: 0.3s ease-in-out;

				&:hover {
					border: 2px solid #6db6ff;
				}
			}
		}

		.range__trackListRangeInner {
			background-color: #4e55a2;
		}
	}

	&__track {
		background-color: #2c2c2e;
		height: 6px;
		width: calc(100% - 16px);
		border-radius: 16px;
		position: relative;
		align-self: center;

		&___disabled {
			pointer-events: none;

			.range__thumb {
				border: 4px solid #4e4e4e;
			}
		}
	}

	&__trackList {
		display: flex;
		justify-content: space-between;
		position: absolute;
		top: -5px;
		left: -8px;
		width: calc(100% + 16px);
		z-index: 1;
	}

	&__trackListRange {
		position: absolute;
		width: 100%;
		height: 6px;
		width: calc(100% - 16px);
		border-radius: 16px;
		left: 8px;
		top: 5px;
		z-index: 1;
		cursor: pointer;
	}

	&__trackListRangeInner {
		background-color: #4e55a2;
		height: 100%;
		width: 0;
	}

	&__trackButton {
		height: 16px;
		width: 16px;
		border: 2px solid #2c2c2e;
		background-color: var(--main-background-color);
		border-radius: 100%;
		position: relative;
		z-index: 2;

		&___checked {
			background-color: #4e55a2;
			border: 2px solid #fff;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				border: 2px solid #6db6ff;
			}
		}
	}

	&__thumbWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 2 !important;

		&:active {
			.range__thumb {
				transition: 0.3s ease;
				transform: scale(0.85);
				border: 4px solid #0080ff;
			}
		}
	}

	&__thumb {
		width: 24px;
		height: 24px;
		background-color: #2c2c2e;
		border: 4px solid #4e55a2;
		border-radius: 100%;
		top: -18px;
		position: relative;
	}

	&__thumbText {
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		top: -6px;
		left: -1px;
		position: relative;
		opacity: 0;

		transition: 0.3s ease-in-out;
	}

	&__percents {
		display: flex;
		justify-content: space-between;
		margin-top: 11px;
	}

	&__percentText {
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		min-width: 16px;
		text-align: center;
	}
}
