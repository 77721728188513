.nav {
	display: flex;
	align-items: center;
	flex: 1;

	&__list {
		display: flex;
		align-items: center;
		list-style: none;
		padding: 0;
		flex: 1;
		margin: 0 16px;

		&::after {
			content: "";
			display: flex;
			background-color: var(--header-separator-color);
			width: 1px;
			height: 20px;

			@media screen and (max-width: 1259px) {
				display: none;
			}
		}
	}

	&__notificationButtonWrapper {
		display: flex;
		padding: 12px;
		height: 68px;
		background-color: #202028;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&__notificationButton {
		display: flex;
		flex: 1;
		padding: 12px;
		justify-content: space-between;
		align-items: center;
		border-radius: 8px;
		background: #292932;

		p {
			padding: 0;
			margin: 0;
		}
	}

	&__notificationButtonText {
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.14px;
	}

	&__notificationExplore {
		display: flex;
		align-items: center;
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.16px;

		svg {
			margin-left: 4px;
		}
	}

	&__notificationHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 12px;
		background-color: #202028;
		height: 50px;
		min-height: 50px;
	}

	&__notificationReadAll {
		background-color: #4e55a2;
		border-radius: 8px;
		color: var(--color-text-primary);
		font-size: 14px;
		font-weight: 500;
		padding: 8px 16px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.6;
			}
		}
	}

	&__notificationCounter {
		color: #4e55a2;
		margin-right: 5px;
	}

	&__notificationTitle {
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		margin: 0;
		padding: 0;
	}

	&__firstListWrap {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow: auto;
	}

	&__notificationNoNewWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100px;
	}

	&__notificationNoNew {
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		margin: 0;
		padding: 0;
	}

	&__notificationsButton {
		display: flex;
		flex-direction: row;
		flex: 1;
		padding: 16px;

		&___unRead {
			background-color: #292932;
		}

		svg {
			margin-right: 16px;
		}
	}

	&__notificationsMessage {
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.16px;
	}

	&__notificationsContent {
		display: flex;
		flex-direction: column;
	}

	&__userData {
		border-radius: 8px;
		background: #292932;
		color: var(--color-text-header-secondary);
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;

		p {
			margin: 0;
		}
	}

	&__userText {
		color: var(--color-text-primary);
	}

	&__userDataTop {
		display: flex;
		justify-content: space-between;
		padding: 8px 12px;
		border-bottom: 1px solid rgba(84, 84, 88, 0.36);
	}

	&__userButtonTwoFactor {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 12px;
		padding: 12px 16px;
		border-radius: 8px;
		background: #4e55a2;
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.6;
			}
		}
	}

	&__userList {
		margin-top: 12px;
		padding: 0;
		list-style: none;
	}

	&__ordersButton {
		flex: 1;
		display: flex;
		align-items: center;
		padding: 12px;
		border-radius: 8px;

		path {
			transition: 0.3s ease-in-out;
		}

		.nav__ordersButtonWrapper {
			display: flex;
			justify-content: space-between;
			flex: 1;
			align-items: center;
			padding-left: 8px;

			span {
				transition: 0.3s ease-in-out;
				color: var(--color-text-primary);
				font-family: "Roboto", sans-serif;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}

			svg {
				opacity: 0;
				transform: translateX(-10px);
				transition: 0.3s ease-in-out;
			}
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: #292932;
				opacity: 1;
				transform: translateX(0px);

				span {
					color: #4e55a2;
				}
			}

			&:hover svg {
				opacity: 1;
				transform: translateX(0px);
			}

			&:hover path {
				stroke: #4e55a2;
			}
		}
	}

	&__userButton {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px;
		border-radius: 8px;

		span {
			transition: 0.3s ease-in-out;
			color: var(--color-text-primary);
			font-family: "Roboto", sans-serif;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}

		svg {
			opacity: 0;
			transform: translateX(-10px);
			transition: 0.3s ease-in-out;
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: #292932;
				opacity: 1;
				transform: translateX(0px);

				span {
					color: #4e55a2;
				}
			}

			&:hover svg {
				opacity: 1;
				transform: translateX(0px);
			}
		}
	}

	&__userButtonLogOut {
		margin-top: 16px;
		color: #ef454a;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		padding: 12px;
		border-radius: 8px;
		background: #292932;
		width: 100%;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.6;
			}
		}
	}

	&__userDataBottom {
		display: flex;
		flex: 1;
		justify-content: space-between;
		padding: 12px;

		&___user {
			width: 100%;
			color: var(--color-text-header-secondary);
			font-family: "Roboto", sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;

			span {
				display: flex;
				align-items: center;

				&:nth-child(1) {
					margin-right: auto;
				}
			}
		}

		&___money {
			flex-direction: column;
			width: 100%;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;

			@media (hover: hover) and (pointer: fine) {
				transition: 0.2s ease-in-out;

				&:hover {
					background-color: #4c4c4c;
				}
			}
		}
	}

	&__userDataBottomMoneyText {
		color: var(--color-text-primary);
		font-size: 24px;
		font-weight: 500;
		line-height: 28px;
	}

	&__userDataBottomCurrencyText {
		color: var(--color-text-header-secondary);
		font-size: 16px;
		font-weight: 500;
		line-height: 28px;
	}

	&__userDataBottomBTCText {
		color: var(--color-text-header-secondary);
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}

	&__dataText {
		color: rgba(235, 235, 245, 0.3);
		font-size: 11px;
		font-weight: 500;
		line-height: 20px;
		margin: 4px 0 0 0;
		padding: 0;
	}

	&__notificationsDate {
		color: var(--color-text-header-secondary);
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.14px;
		margin-top: 4px;
		text-align: left;
	}

	&__firstList {
		position: absolute;
		left: 0;
		top: 44px;
		list-style: none;
		min-width: 382px;
		background-color: #202028;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		z-index: 10;

		opacity: 0;
		pointer-events: none;
		transition: 0.4s ease-in-out;

		&___active {
			flex-direction: column-reverse;

			.nav__itemFirst {
				margin-bottom: 16px;
				margin-top: 0;

				&:first-child {
					margin-bottom: 0;
				}
			}
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				transition: 0s ease-in-out;
			}
		}

		&___notifications {
			max-height: 358px;
			right: -105px;
			left: unset;
			padding: 0px;

			.nav__itemFirst {
				margin-top: 0;
				display: flex;
				flex: 1;

				&::after {
					display: none !important;
				}
			}
		}

		&___user {
			left: unset;
			right: -158px;
			padding: 16px;

			.nav__itemFirst {
				margin-top: 8px;
				display: flex;
				flex: 1;

				&::after {
					display: none !important;
				}

				&:nth-child(1) {
					margin-top: 0;
				}
			}
		}

		&___orders {
			left: unset;
			right: -210px;
			padding: 16px;

			.nav__userList {
				margin-top: 0;
			}
		}

		&___wallet {
			left: unset;
			right: -306px;
			padding: 16px;

			.nav__itemFirst {
				margin-top: 8px;
				display: flex;
				flex: 1;

				&::after {
					display: none !important;
				}

				&:nth-child(1) {
					margin-top: 0;
				}
			}
		}

		&___lang {
			left: unset;
			right: -55px;
			min-width: 350px;
			display: grid;
			grid-template-columns: 140px 140px 140px;
			grid-gap: 8px;

			.nav__itemFirst {
				display: flex;
				flex: 1;
				margin-top: 0;

				&::after {
					display: none !important;
				}
			}

			@media (hover: hover) and (pointer: fine) {
				.nav__langButton:hover {
					background: #292932;
				}
			}
		}
	}

	&__itemFirst {
		margin-top: 16px;
		position: relative;

		&___disabled {
			pointer-events: none;
			opacity: 0.6;
		}

		&:nth-child(1) {
			margin-top: 0;
		}

		&___checked {
			.nav__langButton {
				color: #4e55a2;
				font-weight: 500;
				background: #292932;
			}
		}

		@media (hover: hover) and (pointer: fine) {
			.nav__linkFirstHeadline {
				transition: color 0.3s ease-in-out;

				svg {
					transition: 0.3s ease-in-out;
				}
			}

			&:hover,
			&___active {
				&::after {
					content: "";
					display: flex;
					position: absolute;
					height: 100%;
					right: -16px;
					width: 17px;
					top: 0;
				}

				.nav__tradingList {
					opacity: 1;
					pointer-events: unset;
				}

				.nav__linkFirst {
					background: #292932;
				}

				.nav__linkFirstHeadline {
					color: #4e55a2;

					svg {
						opacity: 1;
						transform: translateX(0);
					}
				}
			}

			&:hover ~ li {
				&::after {
					content: "";
					display: flex;
					position: absolute;
					height: 100%;
					right: -16px;
					width: 17px;
					top: 0;
				}

				.nav__tradingList {
					opacity: 0;
					pointer-events: none;
				}

				.nav__linkFirst {
					background: transparent;
				}

				.nav__linkFirstHeadline {
					color: var(--color-text-primary);

					svg {
						opacity: 0;
						transform: translateX(-10px);
					}
				}
			}
		}
	}

	&__langButtonCheck {
		display: flex;
		margin-left: auto;
	}

	&__langButton {
		display: flex;
		align-items: center;
		padding: 12px;
		flex: 1;
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		margin-top: 8px;
		border-radius: 8px;
		transition: 0.3s ease-in-out;

		&:nth-child(1) {
			margin-top: 0;
		}
	}

	&__linkFirst {
		display: flex;
		flex-direction: column;
		padding: 12px;
		border-radius: 8px;
		transition: 0.3s ease-in-out;
	}

	&__linkFirstHeadline {
		display: flex;
		align-items: center;
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		justify-content: space-between;
		flex: 1;

		svg {
			opacity: 0;
			transform: translateX(-10px);
		}
	}

	&__linkFirstText {
		display: flex;
		margin-top: 8px;
		color: var(--color-text-header-secondary);
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.16px;
	}

	&__item {
		display: flex;
		align-items: center;
		margin-right: 16px;
		position: relative;

		&___disabled {
			pointer-events: none;
			opacity: 0.3;
		}

		&:nth-child(7) {
			margin-right: auto;
		}

		@media screen and (max-width: 1389px) {
			margin-right: 8px;
		}

		@media screen and (max-width: 1259px) {
			&:nth-child(n + 8) {
				display: none;
			}
		}

		@media only screen and (max-width: 1024px) {
			display: none;
		}

		@media (hover: hover) and (pointer: fine) {
			.nav__button .nav__buttonArrow {
				transition: 0.3s ease-in-out;
			}

			.nav__firstList {
			}

			&:hover {
				.nav__button .nav__buttonArrow {
					transform: rotate(-90deg);
				}

				.nav__firstList {
					opacity: 1;
					pointer-events: unset;
				}
			}
		}
	}

	&__buttonSign {
		padding: 6px 12px;
		color: var(--color-text-primary);
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		border-radius: 8px;

		&___up {
			background: #4e55a2;

			padding: 6px 20px;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.65;
			}
		}
	}

	&__button {
		display: flex;
		align-items: center;
		padding: 12px 8px;
		position: relative;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.65;
			}
		}
	}

	&__buttonText {
		font-family: "Roboto", sans-serif;
		font-size: 16px;
		line-height: 20px;
		font-weight: 500;
		color: var(--color-text-primary);
		padding-right: 2px;
	}

	&__buttonCount {
		font-family: "Roboto", sans-serif;
		font-size: 10px;
		line-height: 14px;
		font-weight: 500;
		color: var(--color-text-primary);
		padding: 0 3px 0 4px;
		border-radius: 14px;
		background-color: #4e55a2;
		min-width: 14px;
		text-align: center;
		position: absolute;
		left: 18px;
		top: 6px;
	}

	&__tradingList {
		position: absolute;
		right: -416px;
		top: -16px;
		width: 400px;
		height: 560px;
		background-color: #292932;
		transition: 0.3s ease-in-out;
		opacity: 0;
		pointer-events: none;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		display: flex;
		flex-direction: column;

		&___margin {
			top: -104px;
		}
	}

	&__tradingListUl {
		display: flex;
		list-style: none;
		padding: 0 16px;
		margin: 0;
		border-bottom: 1px solid rgba(84, 84, 88, 0.36);
	}

	&__tradingListTabButton {
		display: flex;
		color: var(--color-text-header-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding-top: 12px;
		padding-bottom: 10px;
		border-bottom: 2px solid #4e55a200;
		cursor: pointer;
		margin-right: 16px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				color: rgba(235, 235, 245, 0.8);
				border-bottom: 2px solid #4e55a230;
			}
		}
	}

	&__tradingListTabRadio:checked + label {
		color: var(--color-text-primary);
		border-bottom: 2px solid #4e55a2;

		&:hover {
			color: var(--color-text-primary);
		}
	}

	&__tradingCoins {
		flex: 1;
		width: 100%;
		padding: 0 16px 12px;
		list-style: none;
		margin: 0;
		display: flex;
		flex-direction: column;
		overflow: auto;
		margin-top: 12px;
	}

	&__tradingCoinsItem {
		margin-top: 12px;

		&:first-child {
			margin-top: 0;
		}
	}

	&__tradingCoinWrapper {
		padding: 8px;
		display: flex;
		align-items: center;
		border-radius: 8px;
		width: 100%;
		text-align: left;

		svg {
			opacity: 0;
			transition: 0.3s ease-in-out;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				background-color: #202028;

				svg {
					opacity: 1;
				}
			}
		}
	}

	&__tradingCoinImage {
		margin-right: 10px;
		border-radius: 100%;
		width: 32px;
		height: 32px;
	}

	&__tradingCoinContent {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__tradingCoinPair {
		color: var(--color-text-primary);
		font-size: 16px;
		font-weight: 500;
		line-height: 18px;
		text-transform: uppercase;
	}

	&__tradingCoinName {
		margin-top: 1px;
		display: flex;
		align-items: center;
	}

	&__tradingCoinNameText {
		display: flex;
		color: var(--color-text-header-secondary);
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		margin-right: 8px;
	}

	&__tradingCoinMarginText {
		display: flex;
		padding: 2px 6px;
		border-radius: 6px;
		background: rgba(124, 89, 255, 0.2);
		color: #4e55a2;
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		text-transform: uppercase;
	}

	&__depositWithdrawButtons {
		display: flex;
		margin-top: 12px;
		grid-gap: 8px;
	}

	&__depositButton {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		background-color: #4e55a2;
		border-radius: 8px;
		color: var(--color-text-primary);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		height: 44px;

		span {
			padding-left: 4px;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	&__withdrawButton {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		background-color: #292932;
		border-radius: 8px;
		color: var(--color-text-primary);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		height: 44px;

		span {
			padding-left: 4px;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}
}
