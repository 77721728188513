.QRCode {
	display: flex;
	position: relative;

	&___disabled {
		opacity: 0.5;

		pointer-events: none;
	}

	@media screen and (max-width: 1259px) {
		display: none;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover .QRCode__modal {
			opacity: 1;

			pointer-events: unset;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		padding: 12px 0;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.65;
			}
		}
	}

	&__modal {
		position: absolute;
		right: -5px;
		top: 44px;
		list-style: none;
		background-color: var(--popups-main-background-color);
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		z-index: 20;

		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
	}

	&__field {
		border-radius: 8px;
		background: var(--popups-secondary-background-color);
		width: 180px;
		height: 180px;
		margin: 0 auto;
	}

	&__modalText {
		color: var(--color-text-primary);
		font-family: "Roboto", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.16px;
		text-align: center;
		margin-top: 12px;
		margin-bottom: 0;
	}

	&__apps {
		display: flex;
		gap: 8px;
		margin-top: 16px;
	}

	&__appLink {
		display: flex;

		img {
			border: 1px solid #fff;
			border-radius: 12px;
		}
	}
}
