.passwordRules {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0.5em;
	border-radius: 1em;
	border: 1px solid rgba(198, 198, 200, 0.08);
	background-color: #f2f2f7;
	padding: calc(1em - 1px) 1em;

	&__item {
		display: flex;
		align-items: center;
		gap: 0.56em;

		span {
			color: #000;
			font-size: 0.875em;
			font-weight: 500;
			line-height: 114.286%;
			letter-spacing: -0.00394em;
		}
	}
}
