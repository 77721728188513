@import "src/assets/styles/common/mixins";

.container {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0;
	flex: 1;

	@include max-w(xxl) {
		min-height: 100vh;
		flex: 1 0 100%;
		max-width: 100%;
	}
}
