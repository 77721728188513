.adjustLeverage {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100000;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	opacity: 0;
	visibility: hidden;

	&___open {
		opacity: 1;
		visibility: visible;
		transition: opacity 0.3s ease-in-out;
	}

	&__closeButton {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	&__content {
		background-color: #1c1c1e;
		width: 420px;
		border-radius: 8px;
		position: relative;
		z-index: 2;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba(84, 84, 88, 0.36);
		padding: 16px 16px 8px 16px;
	}

	&__headerText {
		padding: 0;
		margin: 0;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		line-height: 18px;
	}

	&__container {
		padding: 16px 16px 8px 16px;
	}

	&__containerInput {
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #2c2c2e;
		border-radius: 8px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		margin-top: 4px;
	}

	&__containerText {
		margin: 0;
		color: #fff;
		font-size: 14px;
		font-weight: 400;
	}

	&__buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 16px;
		padding: 8px 16px 16px 16px;
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 48px;
		border-radius: 8px;
		background-color: #2c2c2e;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		transition: 0.3s ease-in-out;

		&___save {
			background-color: #4e55a2;
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
	}
}
