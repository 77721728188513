@import "src/assets/styles/common/mixins";

.container {
	background-color: #101014;
	width: 100%;
}

.auth_form {
	@include flexbox(flex-start, center, column);
	@include card();
	max-width: 550px;
	width: 100%;
	margin: 40px auto;
	font-size: 16px;
	line-height: 23px;
	padding: 45px 90px;

	> a {
		margin: 0 0 25px;

		&:last-child {
			margin: 85px auto 25px;
		}
	}

	> img {
		margin: 0 0 15px;
	}

	> h1 {
		font-weight: bold;
		font-size: 24px;
		text-align: center;
		margin: 0 0 18px;
		color: var(--color-primary);
	}

	form {
		display: grid;
		width: 100%;
		grid-template: auto / 1fr;
		grid-gap: 20px;
		margin-top: 8px;

		> button {
			margin-top: 25px;
		}
	}

	> span {
		color: var(--color-secondary);
		font-size: 16px;
		margin: 20px 0;
		text-align: center;

		> a {
			color: var(--color-blue);
		}
	}

	@include max-w(md) {
		padding: 20px;
		margin: 10px auto;
		border: none;
		background: transparent;

		> a {
			margin: 0 0 15px;

			&:last-child {
				margin: auto 25px;
			}
		}

		> img {
			max-height: 100px;
		}

		> h1 {
			margin: 0 0 25px;
		}
	}
}

.restore_2fa_container {
	@include flexbox(flex-start, center, column);
	margin: 5px 0 14px;
}

.pt16 {
	padding-top: 16px;
}

.two_fa_apps {
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 10px;
	margin-bottom: 14px;

	img {
		background-color: var(--card-header-background);
		border-radius: 16px;
	}
}

.register_successful {
	@include flexbox(center, center, column);
	max-width: get-breakpoint-value(md);
	width: 100%;

	> span {
		font-size: 16px;
		font-weight: 400;
		color: var(--color-secondary);
	}

	> img {
		margin-top: 20px;
	}
}

.register_name_surname {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 12px;
}

.invite_code {
	display: grid;
	grid-template: auto / 1fr;
	justify-items: self-start;
	grid-gap: 20px;
	width: 100%;
}

.invite_code_button {
	font-size: 14px;
	line-height: 19px;
	color: var(--color-secondary);

	i {
		font-size: 12px;
		margin-right: 10px;
	}

	span {
		border-bottom: 1px dashed;
		border-color: var(--color-secondary);
	}

	&:hover {
		color: var(--color-blue);

		i {
			color: var(--color-blue);
		}

		span {
			border-color: var(--color-blue);
		}
	}

	&.active {
		color: var(--color-blue);

		i {
			color: var(--color-blue);
		}

		span {
			border-color: var(--color-blue);
		}
	}
}

.reset_password_form_container {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	gap: 20px;
	margin-top: 10px;
}

.reset_password_success_container {
	@include flexbox(flex-start, center, column);
	width: 100%;
	gap: 20px;

	> a {
		width: 100%;
	}
}

.reset_password_success_header {
	color: var(--color-secondary);
	font-size: 14px;
	text-align: center;
}
