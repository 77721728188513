@import "assets/styles/common/mixins";

.modal_container {
	max-height: 90%;

	@include max-w(md) {
		max-height: 100%;
	}

	.stepper {
		margin-top: 14px;
	}
}

.content {
	padding: 20px;
}

.modal_label {
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	text-align: left;
	color: var(--color-secondary);
}

.invalid_previews {
	display: grid;
	grid-template: auto / repeat(3, 1fr);
	grid-gap: 20px;
	width: 100%;
	margin-top: 10px;
}

.invalid_previews_item {
	@include flexbox(flex-start, center, column);
	font-size: 12px;
	color: var(--color-secondary);

	img {
		margin-bottom: 4px;
	}
}

.modal_instruction {
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 10px;
	justify-items: center;
	margin: 16px auto 0;

	span {
		grid-column: 1 / 3;
		margin-top: 5px;
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		color: var(--color-secondary);
	}

	i {
		&.check {
			color: var(--color-green);
		}

		&.cancel {
			color: var(--color-red);
		}
	}

	@include max-w(md) {
		> img {
			width: 100%;
			height: auto;
		}
	}

	@include max-h(800px) {
		> img {
			max-height: 140px;
			height: auto;
			width: auto;
		}
	}
}

.modal_instruction_img_rules {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	padding: 20px 40px;

	@include max-w(xs) {
		padding: 20px;
	}
}

.modal_instruction_img_rules_item {
	@include flexbox(flex-start, center);
	font-size: 14px;
	color: var(--color-secondary);

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	svg {
		width: 14px;
		min-width: 14px;
		height: auto;
	}

	span {
		margin: 1px 0 0 6px;
	}
}

.modal_upload_file {
	@include flexbox();
	gap: 5px;
	width: 100%;
	padding: 5px;
	max-height: 180px;
	min-height: 177px;
	overflow-x: auto;
	margin: 0;
	@include tiny-scrollbar();
}

.modal_upload_file_preview {
	position: relative;
	@include flexbox();
	min-height: 100px;
	height: 100px;
	border-radius: 5px;
	padding: 5px;
	width: 100%;
	max-width: 280px;
	margin: 0 auto;
	color: var(--input-normal);
}

.modal_upload_file_preivew_content {
	@include flexbox(center, center);
	@include transition(border-color);
	position: relative;
	border-radius: 5px;
	border: 2px dashed var(--input-normal);
	padding: 22px;
	height: 150px;
	width: 100%;

	background: var(--dropzone-preview-background);
	background: radial-gradient(
		circle,
		var(--dropzone-preview-background) 10%,
		rgba(0, 0, 0, 0) 100%
	);

	&:hover {
		border-color: var(--color-secondary);
	}

	.delete_button {
		@include flexbox(center, center);
		position: absolute;
		top: -12px;
		right: -12px;
		background-color: var(--color-red);
		border-radius: 50%;
		width: 24px;
		height: 24px;
		border: 2px solid #fff;

		i {
			font-size: 10px;
			color: #fff;
		}
	}

	img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
		border-radius: 15px;
	}
}

.modal_upload_file_zone {
	@include flexbox(center, center, row);
	position: relative;
	border-radius: 5px;
	border: 2px dashed var(--color-blue);
	min-height: 46px;
	font-weight: normal;
	padding: 10px 20px;
	font-size: 16px;
	line-height: 23px;
	text-align: center;
	color: var(--color-blue);
	width: 100%;
	cursor: pointer;

	&:hover {
		background-color: #4e55a21c;
	}

	& i {
		color: var(--color-blue);
		font-size: 16px;
		margin-right: 10px;
	}

	& input {
		display: none;
	}

	&.safari {
		& input {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			opacity: 0;
		}
	}

	@include max-w(md) {
		padding: 10px 20px;
	}
}

.modal_upload_more_button {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	text-align: center;
	color: var(--color-blue);

	& i {
		color: var(--color-blue);
		font-size: 36px;
		margin-bottom: 5px;
	}
}

.modal_upload_file_error {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 120%;
	margin-top: 6px;
	width: 100%;
	text-align: left;
	color: var(--color-red);

	& i {
		font-size: 12px;
		margin-right: 5px;
		color: var(--color-red);
	}
}

.form_preview_image {
	display: flex;
	justify-content: center;
	align-items: center;
	// background: var(--dropzone-preview-background);
	border-radius: 14px;
	height: 94px;
	min-height: 94px;

	i {
		font-size: 42px;
		color: var(--color-green) !important;
	}

	img {
		width: auto;
		height: 100%;
	}
}

.form_upload_button {
	@include transition(background-color);
	@include flexbox(flex, center);
	position: relative;
	border-radius: 5px;
	min-height: 94px;
	height: 94px;
	padding: 0 35px;
	text-align: center;
	font-size: 16px;
	line-height: 19px;
	cursor: pointer;
	outline: none;
	border: 1px solid var(--color-blue) !important;
	color: var(--color-blue);

	&.disabled {
		border-color: var(--color-disabled) !important;
		color: var(--color-disabled);
		cursor: not-allowed;
	}

	&.disabled:hover {
		background-color: var(--color-disabled-hover);
	}

	&.error {
		border-color: var(--color-red) !important;
		color: var(--color-red);
	}

	&.error:hover {
		background-color: var(--color-red-hover);
	}

	&.verified {
		border-color: var(--color-green) !important;
		color: var(--color-green);
	}

	&.verified:hover {
		background-color: var(--color-green-hover);
	}

	&:hover {
		background-color: var(--color-blue-hover);
	}
}

.upload_file_zone_container {
	width: 100%;
	margin-bottom: 12px;
}
