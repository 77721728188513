.signInSignUp {
	display: flex;
	width: 100%;
	padding: 6px 16px;
	justify-content: center;
	align-items: center;
	margin-top: 32px;

	&__text {
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		line-height: 16px;
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 6px 20px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		margin: 0 8px;
		border-radius: 8px;
		background: #2c2c2e;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}

		&___signUp {
			background: #4e55a2;
		}
	}
}
