.pinCode {
	font-size: 16px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 100%;
	flex: 1;

	padding: 2em 1em;

	&__wrapper {
		width: 100%;
		max-width: 26em;

		display: flex;
		flex-direction: column;
	}

	div[class~="otp-container"] {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 3.25em;
		gap: 0.5em;
		margin-top: 2em;

		div {
			flex: 1;
		}

		input {
			height: 100%;
			width: 100%;
			border-radius: 0.75em;
			border: 1px solid rgba(198, 198, 200, 0.2);
			background-color: #f2f2f7;
			outline: none;
			text-align: center;
			font-size: 1.5em;
			margin: 0;
			transition: border-color 200ms ease-in-out;
			color: #000000;

			&:focus {
				border-color: #4e55a2;
			}

			&:hover:not(:focus) {
				border-color: rgba(198, 198, 200, 0.36);
			}

			&[class~="otp-error"] {
				border-color: #ef454a;
				color: #ef454a;

				&:focus {
					border-color: #4e55a2;
				}
			}
		}
	}

	&__delayWrapper {
		margin-top: 2em;
		text-align: center;
	}

	&__text,
	&__resendButton {
		color: rgba(60, 60, 67, 0.6);
		font-size: 1em;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: -0.0025em;
	}

	&__resendButton {
		color: #4e55a2;
		font-weight: 400;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		margin-top: 5em;
	}
}
