.input {
	display: flex;
	width: 100%;
	flex: 1;
	border-radius: 8px;
	background: #2c2c2e;
	position: relative;
	box-sizing: border-box;
	transition: box-shadow 0.2s ease;

	&:hover {
		box-shadow: inset 0 0 4px #4e55a280;
	}

	&___focus {
		box-shadow: inset 0 0 4px #4e55a2;

		&:hover {
			box-shadow: inset 0 0 4px #4e55a2;
		}
	}

	&___disabled {
		pointer-events: none;
		opacity: 0.36;
	}

	&___errorInput {
		box-shadow: inset 0 0 4px #ef454a80;

		&:hover {
			box-shadow: inset 0 0 4px #ef454a;
		}
	}

	&__errorText {
		padding: 0;
		margin: 6px 0 0 0;
		color: #ef454a;
		font-size: 12px;
	}

	&__elem {
		padding: 13px 0 11px;
		display: flex;
		flex: 1;
		border-radius: 8px;
		background: transparent;
		border: none;
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		min-width: 0;
		box-sizing: border-box;
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:hover {
			border: none;
		}

		&:focus,
		&:focus-visible {
			border: none;
			outline: none;
			padding: 13px 0 11px;
		}

		&::placeholder {
			font-family: "Roboto", sans-serif;
			color: rgba(235, 235, 245, 0.6);
			font-size: 14px;
			font-weight: 400;
			font-style: normal;
			line-height: normal;
		}
	}

	&__prefixIcon {
		pointer-events: none;
		height: 16px;
		width: 16px;
		margin-right: 6px;
		box-sizing: border-box;
	}

	&__postfixIcon {
		pointer-events: none;
		height: 16px;
		width: 16px;
		box-sizing: border-box;
	}

	&__prefixWrapper {
		padding: 12px 0 12px 16px;
		box-sizing: border-box;
	}

	&__postfixWrapper {
		padding: 13px 16px 11px 0;
		box-sizing: border-box;
	}

	&__prefixText,
	&__postfixText {
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		text-transform: uppercase;
		box-sizing: border-box;
	}

	&__prefixText {
		margin-right: 6px;
	}

	&__postfixText {
		margin-left: 6px;
	}
}
