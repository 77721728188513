@mixin home-page-headline {
	color: var(--headline-home-page);
	text-align: center;
	font-size: 40px;
	font-weight: 600;
	line-height: 132%;
	letter-spacing: -2px;

	@media screen and (max-width: 1299px) {
		font-size: 36px;
	}

	@media screen and (max-width: 993px) {
		font-size: 32px;
	}

	@media screen and (max-width: 767px) {
		line-height: 140%;
		letter-spacing: -0.102px;
		text-align: left;
	}
}

@mixin home-page-content {
	color: var(--content-text-home-page);
	text-align: center;
	font-size: 18px;
	font-weight: 400;
	line-height: 132%;
	letter-spacing: -0.3px;

	@media screen and (max-width: 1299px) {
		font-size: 16px;
	}

	@media screen and (max-width: 767px) {
		text-align: left;
	}
}

@mixin home-page-control-buttons {
	border-radius: 32px;
	background: var(--arrow-button-background-color-home-page);
	display: flex;
	padding: 16px 18px;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 993px) {
		padding: 12px 14px;
	}
}

@mixin home-page-container {
	width: 100%;
	max-width: 1660px;
	padding: 0 80px;
	margin: 0 auto 0;

	@media screen and (max-width: 1199px) {
		padding: 0 40px;
	}

	@media screen and (max-width: 993px) {
		padding: 0 24px;
	}
}

@mixin main-container {
	width: 100%;
	max-width: 1660px;
	padding: 0 20px;
	margin: 0 auto 0;
}

@mixin home-page-input {
	flex: 1;
	min-height: 60px;
	padding: 0 12px;
	border-radius: 12px;
	border: 1px solid var(--input-border-color);
	background: var(--input-background-color);

	color: var(--input-text-color);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.3px;
	transition: 0.3s ease-in;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			border-color: var(--input-border-color--hover);
		}
	}

	@media screen and (max-width: 1199px) {
		min-height: 56px;
	}

	@media screen and (max-width: 993px) {
		min-height: 48px;
	}

	&:focus {
		outline: none;
		border-color: var(--input-border-color--focus);
	}

	&::placeholder {
		color: var(--input-text-color--placeholder);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.3px;
	}
}

@mixin home-page-main-button($secondary: false) {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 18px 20px;
	border-radius: 12px;
	background-color: #4e55a2;
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.1px;
	gap: 4px;
	transition: 0.3s ease-in;

	&:active {
		background-color: #7753ff;
	}

	&:disabled {
		pointer-events: none;
		background-color: #c5cbe1;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background-color: #896aff;
		}
	}

	@if $secondary {
		background: #fff;
		color: #4e55a2;

		&:active {
			background: rgba(255, 255, 255, 0.9);
		}

		&:disabled {
			pointer-events: none;
			background-color: #c5cbe1;
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: rgba(255, 255, 255, 0.7);
			}
		}
	}

	@media screen and (max-width: 1199px) {
		padding: 15px 20px;
		font-size: 16px;
	}

	@media screen and (max-width: 993px) {
		padding: 12px 20px;
	}
}
