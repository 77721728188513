.logo {
    display: flex;
    align-items: center;
    padding: 12px 0;

    @media (hover: hover) and (pointer: fine) {
        transition: .3s ease-in-out;

        &:hover {
            opacity: .65;
        }
    }
}