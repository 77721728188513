.search {
	position: absolute;
	height: calc(100% - 64px);
	width: 400px;
	max-width: 100vw;
	border-right: 1px solid rgba(84, 84, 88, 0.36);
	background-color: var(--main-background-color);
	bottom: 0;
	z-index: 3;
	left: 0;
	transform: translateX(-400px);
	padding-top: 8px;
	display: flex;
	flex-direction: column;
	transition: 0.3s ease-in-out;
	pointer-events: none;

	&__inputWrapper {
		padding: 12px 16px;
		display: flex;
	}

	&__input {
		flex: 1;
		padding: 12px 16px;
		border-radius: 8px;
		background: #2c2c2e;
		border: none;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		line-height: normal;

		&::placeholder {
			font-family: "Roboto", sans-serif;
			color: rgba(235, 235, 245, 0.6);
			font-size: 14px;
			font-weight: 400;
			font-style: normal;
			line-height: normal;
		}
	}

	&__topList {
		display: flex;
		padding: 0 16px;
		list-style: none;
		margin: 0;
		border-bottom: 1px solid rgba(84, 84, 88, 0.36);
	}

	&__topItem {
		margin-right: 16px;
	}

	&__tabRadio:checked + label {
		color: #fff;
		border-bottom: 2px solid #4e55a2;

		&:hover {
			color: #fff;
		}
	}

	&__tabButton {
		display: flex;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding-top: 12px;
		padding-bottom: 10px;
		border-bottom: 2px solid #4e55a200;
		cursor: pointer;
		margin-right: 16px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				color: rgba(235, 235, 245, 0.8);
				border-bottom: 2px solid #4e55a230;
			}
		}
	}

	&__topTab {
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding-top: 12px;
		padding-bottom: 10px;
		border-bottom: 2px solid #4e55a200;
		cursor: pointer;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				color: rgba(235, 235, 245, 0.8);
				border-bottom: 2px solid #4e55a230;
			}
		}
	}

	&__optionsList {
		display: flex;
		padding: 12px 16px;
		list-style: none;
		margin: 0;
	}

	&__optionsItem {
		margin-right: 16px;
	}

	&__optionsItemButton {
		padding: 6px 8px;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		border-radius: 8px;
		line-height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;

		&___active {
			background-color: #4e55a2;
			color: #fff;
		}
	}

	&__coinsHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 16px;
	}

	&__coinsHeaderText {
		display: flex;
		align-items: center;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		text-align: left;

		&___desc {
			svg {
				transform: rotate(-180deg);
			}
		}

		svg {
			transition: 0.3s ease;
			margin-left: 2px;
		}

		&___current {
			color: #fff;
		}

		&:nth-child(1) {
			flex: 1;
		}

		&:nth-child(2) {
			width: 100px;
		}

		&:nth-child(3) {
			width: 90px;
			text-align: right;
			justify-content: flex-end;
		}
	}

	&__coins {
		flex: 1;
		overflow: hidden;
	}

	&__coinsList {
		height: calc(100% - 32px);
		overflow: auto;
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
	}

	&__coinsItem {
		display: flex;
		width: 100%;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				background-color: #3a3a3c;
			}
		}
	}

	&__favoriteButton {
		padding: 12px 8px 12px 16px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				transform: scale(1.2);
			}
		}
	}

	&__coinLink {
		display: flex;
		padding: 12px 16px 12px 0;
		align-items: center;
		flex: 1;
		text-align: left;
	}

	&__coinName {
		flex: 1;
		display: flex;
		align-items: center;
	}

	&__coinNameText {
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		text-transform: uppercase;
	}

	&__coinNameIndicator {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 2px 6px;
		margin-left: 8px;
		color: #4e55a2;
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		border-radius: 6px;
		background: rgba(10, 132, 255, 0.24);
	}

	&__coinPrice {
		width: 98px;
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
	}

	&__coinPercent {
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		width: 87px;
		text-align: right;

		&___green {
			color: #20b26c;
		}

		&___red {
			color: #ef454a;
		}
	}
}
