.regWelcome {
	font-size: 16px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 100%;
	flex: 1;

	padding: 2em 1em;

	&__wrapper {
		width: 100%;
		max-width: 26em;

		display: flex;
		flex-direction: column;
	}

	&__buttonWrapper {
		margin-top: 4em;
	}
}
