.regJuridicalForm {
	font-size: 16px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 100%;
	flex: 1;

	padding: 2em 1em;

	&__wrapper {
		width: 100%;
		max-width: 26em;

		display: flex;
		flex-direction: column;
	}

	&__inputTopWrapper {
		margin-top: 2em;
	}

	&__inputWrapper {
		margin-top: 1.5em;
	}

	&__inputDoubleWrapper {
		margin-top: 1.5em;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0.5em;
	}

	&__countryWrapper {
		margin-top: 1.5em;
	}

	&__buttonWrapper {
		margin-top: 4em;
	}
}
