.trading {
	display: flex;
	flex: 1;
	flex-grow: 1;
	flex-direction: column;
	font-family: "Roboto", sans-serif;
	position: relative;
	max-width: 100%;

	&__top {
		z-index: 10;
	}

	&__toggleMenu {
		&:checked + div {
			transform: translateX(0);
			pointer-events: unset;
		}
	}

	&__view {
		display: flex;
		background-color: black;
		justify-content: center;
		align-items: center;
		flex: 1;
		flex-grow: 1;
		padding-top: 1px;
		overflow: hidden;
	}

	&__viewText {
		color: #fff;
		font-size: 96px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		opacity: 0.1;
		text-transform: uppercase;
	}

	&__header {
		display: flex;
		flex-direction: row;
		padding: 0 0 0 16px;
		white-space: nowrap;
	}

	&__menuButton {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 11.5px;
		cursor: pointer;

		&:disabled {
			pointer-events: none;
			cursor: pointer;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.6;
			}
		}
	}

	&__info {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 12px 0;
	}

	&__infoLeft {
		padding-right: 16px;
		border-right: 1px solid rgba(84, 84, 88, 0.36);
	}

	&__infoHeadline {
		margin: 0;
		padding: 0;
		color: #fff;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;
	}

	&__skeleton {
		display: inline-flex;

		span {
			display: flex;
		}
	}

	&__infoContent {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 1px;
	}

	&__infoName {
		margin: 0;
		padding: 0;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-decoration-line: underline;
		margin-right: 8px;
		cursor: pointer;
	}

	&__infoIndicator {
		margin: 0;
		background-color: #4e55a23d;
		padding: 2px 6px;
		color: #4e55a2;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		border-radius: 6px;
	}

	&__infoRight {
		padding-left: 16px;
	}

	&__infoPrice {
		margin: 0;
		padding: 0;
		color: #20b26c;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px; /* 112.5% */
		text-transform: uppercase;

		&___minus {
			color: #ef454a;
		}
	}

	&__infoPrice2 {
		margin: 4px 0 0 0;
		padding: 0;
		color: #e6e6e6;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 14px;
	}

	&__listContainer {
		display: flex;
		flex: 1;
		margin: 0 0 0 24px;
		overflow: hidden;
		position: relative;
	}

	&__listWrapper {
		display: flex;
		flex: 1;
		width: 100%;
		overflow: auto;
		scrollbar-width: none;
		min-height: 100%;

		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}

	&__listButtons {
		position: absolute;
		height: 0;
	}

	&__listButtonLeft {
		position: absolute;
		width: 40px;
		left: 0;
		top: 0;
		height: 62px;
		background: linear-gradient(
			90deg,
			var(--main-background-color),
			var(--main-background-color),
			transparent
		);
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-right: 10px;

		svg {
			transform: rotate(90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&__infoModalWrapper {
		position: relative;

		&:hover {
			&::after {
				position: absolute;
				bottom: -8px;
				height: 8px;
				display: flex;
				width: 100%;
				content: "";
			}

			.trading__infoModal {
				opacity: 1;
				pointer-events: unset;
			}
		}
	}

	&__infoModal {
		position: absolute;
		z-index: 20;
		bottom: -6px;
		transform: translateY(100%);
		background-color: #2c2c2e;
		border-radius: 8px;
		width: 380px;
		display: flex;
		flex-direction: column;
		opacity: 0;
		transition: 0.3s ease-in-out;
		pointer-events: none;
	}

	&__infoModalNameWrapper {
		display: flex;
		flex-direction: column;
		margin-left: 10px;
	}

	&__infoModalName {
		margin: 0;
		padding: 0;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		line-height: 19px;
		text-transform: uppercase;
	}

	&__infoModalFullName {
		margin: 1px 0 0 0;
		padding: 0;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
	}

	&__infoModalTableData {
		border-top: 1px solid rgba(84, 84, 88, 0.36);
		display: flex;
		padding: 12px;
	}

	&__infoModalColumn {
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	&__infoModalColumnHeadline {
		padding: 0;
		margin: 0;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
	}

	&__infoModalColumnText {
		margin: 4px 0 0 0;
		padding: 0;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
	}

	&__infoModalLinks {
		border-top: 1px solid rgba(84, 84, 88, 0.36);
		display: flex;
		padding: 12px;
		list-style: none;
		margin: 0;
		flex-wrap: wrap;
		grid-gap: 10px;
	}

	&__infoModalLink {
	}

	&__infoModalLinkText {
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		padding: 4px 6px;
		border-radius: 8px;
		background-color: #3a3a3c;

		span {
			padding-left: 4px;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.2s ease-in-out;

			&:hover {
				background-color: #4e55a2;
			}
		}
	}

	&__infoModalContent {
		display: flex;
		padding: 12px;
		margin: 0;
		white-space: pre-wrap;
		border-top: 1px solid rgba(84, 84, 88, 0.36);
	}

	&__infoModalHeader {
		padding: 12px;
		display: flex;
		align-items: center;
	}

	&__infoModalImage {
		width: 36px;
		height: 36px;
		border: 1px dashed #4e55a2;
		border-radius: 100%;
	}

	&__infoModalImg {
		width: 36px;
		height: 36px;
		border-radius: 100%;
	}

	&__listButtonRight {
		position: absolute;
		width: 40px;
		right: 0;
		top: 0;
		height: 62px;
		background: linear-gradient(
			90deg,
			transparent,
			var(--main-background-color),
			var(--main-background-color)
		);
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-left: 10px;

		svg {
			transform: rotate(-90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		align-items: center;
		list-style: none;
		margin: 0;
		width: auto;
		padding: 0;
	}

	&__item {
		margin-right: 24px;

		&:nth-child(1) {
			.trading__itemValue {
				color: #20b26c;

				&___minus {
					color: #ef454a;
				}
			}
		}
	}

	&__itemTopText {
		margin: 0;
		padding: 0;
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
	}

	&__itemValue {
		margin: 3px 0 0 0;
		padding: 0;
		color: #fff;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 14px;
	}
}
