.thirdParty {
	&__line {
		display: flex;
		align-items: center;
		margin-top: 1.5em;

		&:before,
		&:after {
			content: "";
			flex: 1;
			height: 1px;
			background: #c6c6c8;
		}
	}

	&__lineText {
		color: #c6c6c8;
		font-size: 1em;
		line-height: 125%;
		margin: 0 1.5em;
	}

	&__auth {
		display: flex;
		width: 100%;
		margin-top: 1.5em;
		gap: 1em;
	}

	&__authButton {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		gap: 0.5em;
		padding: 1em;
		border-radius: 1em;
		border: 1px solid rgba(84, 84, 88, 0.36);
		color: #000;
		font-size: 1em;
		line-height: 125%;
		transition: border-color 0.3s ease-in-out;

		@media (hover: hover) {
			&:hover {
				border-color: #000;
			}
		}

		&:disabled {
			opacity: 0.24;
			pointer-events: none;
		}
	}
}
