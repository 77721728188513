@mixin linkStyles {
	color: #4e55a2;
	font-weight: 600;

	@media (hover: hover) {
		&:hover {
			opacity: 0.88;
		}
	}

	&:active {
		opacity: 0.76;
	}

	&:disabled {
		opacity: 0.24;
		pointer-events: none;
	}
}

.loginForm {
	font-size: 16px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 100%;
	flex: 1;

	padding: 2em 1em;

	&__wrapper {
		width: 100%;
		max-width: 26em;

		display: flex;
		flex-direction: column;
	}

	&__descriptionLink {
		@include linkStyles;
		text-decoration-line: underline;
	}

	&__emailSubWrapper {
		margin-top: 2em;
	}

	&__toggle {
		display: flex;
		gap: 1em;
	}

	&__toggleButton {
		flex: 1;
		padding: 0.62em;
		color: rgba(60, 60, 67, 0.6);
		font-size: 1rem;
		font-weight: 500;
		line-height: 125%;
		border-radius: 1em;
		border: 1px solid rgba(198, 198, 200, 0.08);
		transition: 0.3s ease-in-out;

		@media (hover: hover) {
			&:hover {
				color: #000;
				background: #f2f2f7;
			}
		}

		&___active {
			color: #000;
			background: #f2f2f7;
		}
	}

	&__emailWrapper {
		width: 100%;
		margin-top: 0.5em;
		color: #0a0a0a;
	}

	&__passwordWrapper {
		margin-top: 1.5em;
		width: 100%;
	}

	&__buttonWrapper {
		margin-top: 4em;
	}

	&__forgotPasswordLink {
		@include linkStyles;

		font-size: 0.875em;
		font-weight: 500;
		line-height: 142.857%;
	}
}
