.checkBox {
	&__checkmark {
		position: relative;
		display: flex;
		width: 1.25em;
		height: 1.25em;
		min-width: 1.25em;
		min-height: 1.25em;

		&___errors:before {
			border-color: #ff5959 !important;
		}

		&:hover:disabled::before {
			border-color: #000;
		}

		&:hover:disabled::before {
			border-color: rgba(84, 84, 88, 0.36) !important;
		}

		&:disabled {
			opacity: 0.24;
			pointer-events: none;
		}

		&::before {
			content: "";
			display: flex;
			border-radius: 0.25em;
			border: 1px solid rgba(84, 84, 88, 0.36);
			transition: 0.3s ease-in-out;
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
		}

		&::after {
			content: "";
			display: flex;
			position: absolute;
			width: 1em;
			height: 1em;
			left: 50%;
			opacity: 0;
			transition: 0.3s ease-in-out;
			top: 50%;
			transform: translate(-50%, -50%);
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		}
	}

	&__input {
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		margin: 0;

		&:checked + .checkBox__checkmark::before {
			border-color: #4e55a2 !important;
			background-color: #4e55a2;
		}

		&:checked + .checkBox__checkmark::after {
			opacity: 1;
		}

		&:checked:disabled + .checkBox__checkmark {
			opacity: 0.24;
		}
	}

	&__label {
		display: flex;
		align-items: flex-start;
		gap: 0.75em;
		cursor: pointer;
	}

	&__content {
		color: #1c1c1c;
		font-size: 1em;
		font-weight: 400;
		line-height: 125%;
		white-space: break-spaces;

		a {
			color: #4e55a2;
			font-size: 1em;
			font-weight: 400;
			line-height: 125%;
			text-decoration-line: underline;
		}
	}
}
